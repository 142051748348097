import React from "react"
import theme from "theme"
import {
  Theme,
  Strong,
  Text,
  Box,
  Icon,
  Section,
  Image,
} from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
import { MdDone } from "react-icons/md"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>Наші послуги на стадіоні Greenway</title>
        <meta
          name={"description"}
          content={"Неперевершений футбольний досвід"}
        />
        <meta
          property={"og:title"}
          content={"Наші послуги на стадіоні Greenway"}
        />
        <meta
          property={"og:description"}
          content={"Неперевершений футбольний досвід"}
        />
        <meta
          property={"og:image"}
          content={"https://pluravent.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://pluravent.com/img/images.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://pluravent.com/img/images.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://pluravent.com/img/images.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://pluravent.com/img/images.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://pluravent.com/img/images.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://pluravent.com/img/images.png"}
        />
        <meta
          name={"msapplication-TileColor"}
          content={"https://pluravent.com/img/images.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="90px 0 90px 0"
        sm-padding="40px 0"
        sm-min-height="auto"
        display="flex"
        quarkly-title="Advantages/Features-8"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          grid-gap="5%"
          sm-min-width="280px"
          lg-flex-wrap="wrap"
          display="flex"
        />
        <Box
          display="flex"
          width="35%"
          flex-direction="column"
          justify-content="flex-start"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="flex-start"
          lg-margin="0px 0px 60px 0px"
          sm-margin="0px 0px 30px 0px"
          sm-padding="0px 0px 0px 0px"
          lg-flex-direction="row"
          lg-flex-wrap="wrap"
          sm-width="100%"
          md-margin="0px 0px 30px 0px"
        >
          <Text
            margin="0px 0px 8px 0px"
            font="--lead"
            color="--primary"
            width="100%"
            md-width="100%"
            md-margin="0px 0px 16px 0px"
            md-padding="0px 0px 0px 0px"
            text-align="left"
            md-text-align="center"
            sm-text-align="left"
          >
            <Strong sm-text-align="left">стадіон Greenway</Strong>
          </Text>
          <Text
            margin="0px 0px 24px 0px"
            font="--headline2"
            color="--dark"
            width="100%"
            md-width="100%"
            md-margin="0px 0px 32px 0px"
            md-padding="0px 0px 0px 0px"
            sm-font="normal 600 40px/1.2 --fontFamily-sans"
            text-align="left"
            md-text-align="center"
            sm-text-align="left"
          >
            Наші послуги
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            font="--base"
            color="--dark"
            width="100%"
            md-width="100%"
            md-margin="0px 0px 32px 0px"
            md-padding="0px 0px 0px 0px"
            text-align="left"
            md-text-align="center"
            sm-text-align="left"
          >
            На стадіоні "Greenway" ми пропонуємо широкий спектр послуг,
            покликаних забезпечити винятковий футбольний досвід для гравців і
            команд усіх рівнів. Дізнайтеся, що відрізняє наші об'єкти та
            послуги.
          </Text>
        </Box>
        <Box
          width="60%"
          display="grid"
          grid-template-columns="repeat(2, 1fr)"
          grid-gap="48px 32px"
          lg-grid-gap="48px"
          lg-width="100%"
          sm-grid-template-columns="1fr"
          md-grid-gap="36px "
        >
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Комплексні послуги</Strong>
              </Text>
              <Text
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                Стадіон "Greenway" прагне створити першокласне футбольне
                середовище. Наші послуги розроблені таким чином, щоб
                задовольнити потреби наших клієнтів, забезпечуючи безперебійне
                та приємне проведення часу.
              </Text>
            </Box>
          </Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Оренда поля преміум-класу</Strong>
              </Text>
              <Text
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                Високоякісне покриття: На наших полях використовується найкраща
                технологія покриття, що забезпечує гладку та безпечну ігрову
                поверхню, яка імітує натуральну траву.
                <br />
                <br />
                Довговічність: Призначений для інтенсивного використання, що
                гарантує, що поле залишається в найкращому стані.
                <br />
                <br />
                Зручність гри: Забезпечує оптимальний контроль м'яча та комфорт
                гравця, покращуючи вашу гру.
              </Text>
            </Box>
          </Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Тренувальні сесії</Strong>
              </Text>
              <Text
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                Професійний коучинг: ми надаємо доступ до досвідчених тренерів,
                які допоможуть вам покращити ваші навички та стратегії.
                <br />
                <br />
                Персоналізовані тренування: Індивідуальні тренування для
                задоволення індивідуальних потреб гравців.
                <br />
                <br />
                Командні клініки: Групові тренувальні програми, спрямовані на
                покращення командної динаміки та результативності.
              </Text>
            </Box>
          </Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Досвід проведення матчів</Strong>
              </Text>
              <Text
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                Івент-менеджмент: Наша команда готова допомогти з організацією
                матчів, від невеликих ігор до великих турнірів.
                <br />
                <br />
                Планування: Гнучкі варіанти бронювання відповідно до вашого
                графіку.
                <br />
                <br />
                Допоміжний персонал: Персонал на місці для забезпечення
                безперебійного проведення вашого заходу.
              </Text>
            </Box>
          </Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Прокат екіпіровки</Strong>
              </Text>
              <Text
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                Футбольне спорядження: Високоякісне спорядження, доступне
                напрокат, щоб покращити ваш ігровий досвід.
                <br />
                <br />
                М'ячі та набори: Асортимент футбольних м'ячів та наборів для
                різних потреб.
                <br />
                <br />
                Захисне екіпірування: Захисне спорядження для забезпечення
                безпеки гравців.
              </Text>
            </Box>
          </Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Зручності в готелі</Strong>
              </Text>
              <Text
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                Сучасні роздягальні: Просторі та чисті роздягальні, обладнані
                душовими кабінами та шафками.
                <br />
                Комфорт: Створено для максимального комфорту та зручності.
                <br />
                Безпека: Надійне сховище для ваших особистих речей.
                <br />
                Зона відпочинку: На території готелю є різноманітні закуски та
                напої.
                <br />
                Зручність: Легко доступні під час вашого візиту.
                <br />
                Різноманітність: Підбірка здорових варіантів, які допоможуть вам
                зарядитися енергією.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-4"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          width="65%"
          justify-content="center"
          align-items="flex-start"
          margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          display="flex"
          lg-width="100%"
          padding="16px 16px 16px 16px"
          sm-margin="0px 0px 30px 0px"
        >
          <Box
            width="100%"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 125% 0px"
            height="auto"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              bottom={0}
              min-height="100%"
              top={0}
              left={0}
              position="absolute"
              display="block"
              width="100%"
              right={0}
              src="https://pluravent.com/img/4.jpg"
              object-fit="cover"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          align-items="flex-start"
          lg-width="100%"
          lg-margin="0px 0px 0px 0px"
          lg-order="1"
          width="35%"
          flex-direction="column"
          lg-align-items="center"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            padding="0px 0px 65% 0px"
            width="100%"
            height="auto"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              right={0}
              bottom="0px"
              object-fit="cover"
              position="absolute"
              display="block"
              width="100%"
              top="auto"
              min-height="100%"
              src="https://pluravent.com/img/5.jpg"
            />
          </Box>
          <Text
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            margin="24px 0px 48px 0px"
            md-text-align="left"
          >
            Випробуйте все, що може запропонувати стадіон "Greenway". Для отримання більш детальної інформації про наші послуги, будь ласка, зв'яжіться з нами або відвідайте нас особисто. Наша команда завжди готова допомогти вам з будь-якими питаннями.
          </Text>
          <Box
            height="auto"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            width="100%"
            padding="0px 0px 65% 0px"
          >
            <Image
              display="block"
              width="100%"
              top="auto"
              bottom="0px"
              min-height="100%"
              src="https://pluravent.com/img/6.jpg"
              object-fit="cover"
              position="absolute"
              left={0}
              right={0}
            />
          </Box>
          <Text
            margin="24px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Приєднуйтесь до нас на стадіоні "Greenway" і підніміть свій футбольний досвід на новий рівень. Від професійних тренерів до високоякісного обладнання - ми надаємо все необхідне для виняткової футбольної подорожі. Забронюйте тренування вже сьогодні і дізнайтеся, чому стадіон "Greenway" є ідеальним місцем для любителів футболу.
          </Text>
        </Box>
      </Section>
      <Components.Contact />
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
